import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import AboutImage from "../assets/images/image_home_04.jpg";
import Footer from "../components/Footer";

function Projects() {
  return (
    <>
      <Navbar />
      <Hero cName="hero-about" HeroImg={AboutImage} title="Project" />
      <Footer/>
    </>
  );
}

export default Projects;
