import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import AboutImage from "../assets/images/image_home_04.jpg"
import Footer from "../components/Footer";

function Contacts() {
  return (
    <>
       <Navbar />
      <Hero
        cName="hero-about"
        HeroImg={AboutImage}
        title="Contacts"
      />
      <Footer/>
    </>
  );
}

export default Contacts;
