import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import AboutImage from "../assets/images/image_home_02.jpg"
import Footer from "../components/Footer";
import Portopolio from "../components/Portopolio"
import AboutUs from "../components/AboutUs";

function About() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-about"
        HeroImg={AboutImage}
        title="About"
      />
      <Portopolio/>
      <AboutUs/>
      <Footer/>
    </>
  );
}

export default About;
