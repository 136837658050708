import "./FooterStyles.css";

const Footer = () => {
  return (
    <div className="Footer">
      <div className="Footer-top">
        <div>
          <h1>Indonesia Project</h1>
          <p>Choose your destination</p>
        </div>
        <div>
          <a href="/">
            <i className="fa-brands fa-facebook-square"></i>
          </a>
          <a href="/">
            <i className="fa-brands fa-instagram-square"></i>
          </a>
          <a href="/">
            <i className="fa-brands fa-google-plus-square"></i>
          </a>
          <a href="/">
            <i className="fa-brands fa-twitter-square"></i>
          </a>
        </div>
      </div>
      <div className="Footer-bottom">
        <div>
          <h3>Menu</h3>
          <a href="/" className="footer-menu">
            About
          </a>
          <a href="/" className="footer-menu">
            Services
          </a>
          <a href="/" className="footer-menu">
            Project
          </a>
          <a href="/" className="footer-menu">
            Gallery
          </a>
          <a href="/" className="footer-menu">
            Contact
          </a>
        </div>
        <div className="Footer-left">
          <h3>Get in Touch</h3>
          <a href="/" className="footer-menu">
            Jatiwaringin Junction Kav. 11 Jl. Raya Jatiwaringin No. 24 Jakarta
            Timur 13620 Indonesia
          </a>
          <a href="/" className="footer-menu">
            +62 21 2962 2471
          </a>
          <a href="/" className="footer-menu">
            +62 21 2962 2470
          </a>
        </div>
      </div>
      <div className="Footer-copyright">
        Copyright &#169;
          <span class="copyright"> Indonesia Project 2023</span>
      </div>
    </div>
  );
};

export default Footer;
