import { Component } from "react";
import "./NavbarStyles.css";
import { MenuItems } from "./MenuItems";
import { Link } from "react-router-dom";
import Logo from "../assets/images/logo_indonesiaproject.png";

class Navbar extends Component {
  state = { clicked: false };
  handleClicked = () => {
    this.setState({ clicked: !this.state.clicked });
  };
  render() {
    return (
      <nav className="NavbarItems">
        <Link to="/">
          <img src={Logo} alt="nav logo" className="Navbar-logo"/>
        </Link>
        <div className="menu-icons" onClick={this.handleClicked}>
          <i
            className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}
          ></i>
        </div>
        <ul
          className={this.state.clicked ? "navbar-menu active" : "navbar-menu"}
        >
          {MenuItems.map((item, index) => {
            return (
              <li key={index} className="nav-links">
                <Link className="{item.cName}" to={item.url}>
                  <i className={item.icon}>{item.title}</i>
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}

export default Navbar;
