export const MenuItems = [
  {
    title: "Home",
    url: "/",
    cName: "nav-links",
    icon: "fa-solid",
  },
  {
    title: "About",
    url: "/about",
    cName: "nav-links",
    icon: "fa-solid",
  },
  {
    title: "Services",
    url: "/services",
    cName: "nav-links",
    icon: "fa-solid",
  },
  {
    title: "Projects",
    url: "/projects",
    cName: "nav-links",
    icon: "fa-solid",
  },
  {
    title: "Gallery",
    url: "/gallery",
    cName: "nav-links",
    icon: "fa-solid",
  },
  {
    title: "Contacts",
    url: "/contacts",
    cName: "nav-links",
    icon: "fa-brands",
  },

];
