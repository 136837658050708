import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import AboutImage from "../assets/images/image_home_03.jpg";
import Footer from "../components/Footer";

function Services() {
  return (
    <>
      <Navbar />
      <Hero cName="hero-about" HeroImg={AboutImage} title="Services" />
      <Footer/>
    </>
  );
}

export default Services;
