import "./AboutUsStyles.css";

function AboutUs() {
  return (
    <div className="AboutUs-container">
      <h1>Our Vision</h1>
      <p>
        Our vision is to be the most trusted logistics company in Energy with
        mission to deliver the most cost effective services, maintain the
        highest levels of professionalism, ensure the longevity, and accelerate
        growth, mutual benefit, and to contribute for the community.
      </p>
      <h1>Our Teamwork</h1>
      <p>
        Our project logistics professionals are experts in the coordination of
        oversized and heavy ca rgoes, utilizing various modes of transportation
        including heavy-lift geared ships, ballasting barge, skidding, rigging,
        jacking, and oversize cargo specialist using multi-axle trailer or SPMT.
        Our staffs also experienced in a variety of plant construction and other
        types of projects, including power plants, mining, manufacturing plants,
        and oil & gas field projects. Through our worldwide network and
        strategic alliance partners in Indonesia, we are ready to provide total
        project logistics services, even to the most remote destinations in
        Indonesia
      </p>
      <h1>Our Service Offered</h1>
      <p>
        Indonesia Projects Logistics was set up due to we had large increase in
        the volume of our services with mining construction, power plant, and
        oil & gas company in Indonesia by opening a new different company which
        specialized in providing heavy lifting and project cargo transportation
        solutions, as well as for offshore operation and onshore activities. The
        solutions shall be included on-sitetransport, load-outs, heavy lifting,
        rigging, and jacking. We have our engineering can determine the optional
        solution when moving large and/or heavy objects including the stability
        analysis of the loads during the transportation, including the
        involvement range from handling the transportation design, feasibility
        studies, site surveys, shipping arrangement, project customs clearance
        and preparation, up to delivery service arrangement on the final
        position. As the demand for project transportation in Indonesia we keep
        focused experience provides our customers with competitive solution
        without ignoring the technology, safety, comprehensive equipments,
        reliability time delivery and well
      </p>
    </div>
  );
}

export default AboutUs;
