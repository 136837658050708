import { Component } from "react";
import "./PortopolioStyles.css";


class PortopolioData extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <div className="portopolio-content">
          <h2 className="portopolio-text">{this.props.heading}</h2>
          <p>
            {this.props.text}
          </p>
        </div>

        <div className="portopolio-image">
          <img alt="Portopolio" src={this.props.img_01} />
          <img alt="Portopolio" src={this.props.img_02} />
        </div>
      </div>

      
    );
  }
}

export default PortopolioData;
