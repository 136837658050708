import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import HomeImage from "../assets/images/image_home_01.jpg";
import Portopolio from "../components/Portopolio";
import Footer from "../components/Footer";

function Home() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero"
        HeroImg={HomeImage}
        title="The Most Trusted Logistics Company in Energy"
        text="Total Solution For Heavy Lift and Project Cargo Transpotation ( Onshore &amp; OffShore Services )"
        buttonText="Choose Solution"
        url="/"
        btnClass="Show"
      />
      <Portopolio />
      <Footer/>
    </>
  );
}

export default Home;
