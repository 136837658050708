import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import AboutImage from "../assets/images/image_home_03.jpg";
import Footer from "../components/Footer";

function Gallery() {
  return (
    <>
      <Navbar />
      <Hero cName="hero-about" HeroImg={AboutImage} title="Gallery" />
      <Footer/>
    </>
  );
}

export default Gallery;
