import PortopolioData from "./PortopolioData";
import "./PortopolioStyles.css";
import portopolio_01 from "../../src/assets/images/image_01.jpg";
import portopolio_02 from "../../src/assets/images/image_02.jpg";
import portopolio_03 from "../../src/assets/images/image_03.jpg";
import portopolio_04 from "../../src/assets/images/image_04.jpg";

const Portopolio = () => {
  return (
    <div className="portopolio">
      <h1>About Indonesia Project</h1>
      <PortopolioData
      className="portopolio-container"
        heading="Company Introduction"
        text="Indonesia Project Logistics was incorporated in Jakarta in 2005
            and now primarily engaged in supporting offshore oil and gas
            industry in Indonesia. The Company has many experiences in servicing
            and handling general cargo and special cargo for oil and gas
            exploration, drilling and production activities. The activities also
            covers domestics and International shipping which enable the Company
            to participate in the charter and operation of Tugs and Barges,
            Landing Craft Vessel (LCT), Break bulk Vessel and other special
            vessel for supporting many oil & gas company, power plants, mining
            industries and construction companies. Delivery. Our goal is to generate growth together with our clients
            and customers, and profitability by means of value, quality and
            perseverance. We commit to excellence for the customers and
            stakeholders through continuous improvement of the company services
            and technology. We want to be the most trusted logistics company in energy."
        img_01={portopolio_01}
        img_02={portopolio_02}
      />
      
      <PortopolioData
      className="portopolio-container-reverse"
        heading="Company Introduction"
        text="Indonesia Project Logistics was incorporated in Jakarta in 2005
            and now primarily engaged in supporting offshore oil and gas
            industry in Indonesia. The Company has many experiences in servicing
            and handling general cargo and special cargo for oil and gas
            exploration, drilling and production activities. The activities also
            covers domestics and International shipping which enable the Company
            to participate in the charter and operation of Tugs and Barges,
            Landing Craft Vessel (LCT), Break bulk Vessel and other special
            vessel for supporting many oil & gas company, power plants, mining
            industries and construction companies. Delivery. Our goal is to generate growth together with our clients
            and customers, and profitability by means of value, quality and
            perseverance. We commit to excellence for the customers and
            stakeholders through continuous improvement of the company services
            and technology. We want to be the most trusted logistics company in energy."
        img_01={portopolio_03}
        img_02={portopolio_04}
      />
    </div>
  );
};
export default Portopolio;
